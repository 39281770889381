import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="750px"
          image={`url(${mobileHero}) center / cover no-repeat`}
          column
          center
        >
          <CFView
            mt="20px"
            textCenter
            w="100%"
            column
            alignCenter
            justifyBetween
            h="750px"
          >
            <CFImage
              w="85%"
              src={mobileHeroText}
              alt="Bon Ga Korean Restaurant hero text"
              zIndex={98}
            />
            <CFView pulsate mb="20px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView maxWidth="1400px" w="100%" zIndex={90} column justifyBetween>
          <Header />
          <CFView>
            <CFImage
              w="100%"
              src={hero}
              alt="Bon Ga Korean Restaurant hero text"
              zIndex={98}
            />
            <CFView column justifyCenter alignStart zIndex={99} ml="5%">
              <CFImage
                mt="-13%"
                w="40vw"
                maxWidth="520px"
                src={heroText}
                alt="Bon Ga Korean Restaurant hero text"
                zIndex={98}
              />
              <CFView pulsate mv="15px">
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
